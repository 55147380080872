<template>
  <div>
    <template v-if="!link.external">
      <router-link
        :to="{ name: link.routeName }"
        :class="link.class"
        :data-inner-select="link.id"
      >
        <div class="hint__content">{{ link.name }}</div>
      </router-link>
    </template>
    <template v-else>
      <a :href="link.href" target="_blank" :class="link.class">
        <div class="hint__content">{{ link.name }}</div>
      </a>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ServerLink',
  props: ['link', 'server_id'],
}
</script>

<style scoped></style>
