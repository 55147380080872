// import router from '@/router'

export default {
  actions: {},
  mutations: {
    UPDATE_MODAL(state, value) {
      state.show_modal = value
      const $el_cl = document.getElementsByTagName('body')[0].classList
      value ? $el_cl.add('modal-active') : $el_cl.remove('modal-active')
    },
  },
  state: {
    show_modal: false,
  },
  getters: {
    show_modal: (state) => state.show_modal,
  },
}
