<template>
  <div class="container" data-container="navigation">
    <nav class="navigation">
      <div class="nav navigation__nav">
        <div class="nav__list">
          <template v-for="(item, i) in menu_items">
            <template v-if="item.isModal">
              <router-link
                class="nav__link"
                :to="{ name: item.router.name }"
                :key="i"
              >
                <div class="nav__ico">
                  <img
                    :alt="item.description"
                    class="nav__img"
                    :src="item.imgSrc"
                  />
                </div>
                <div class="nav__content">
                  <div class="nav__name">{{ item.name }}</div>
                  <div class="nav__desc">{{ item.description }}</div>
                </div>
              </router-link>
            </template>
            <template v-else>
              <a class="nav__link" :href="item.link" target="_blank" :key="i">
                <div class="nav__ico">
                  <img
                    :alt="item.description"
                    class="nav__img"
                    :src="item.imgSrc"
                  />
                </div>
                <div class="nav__content">
                  <div class="nav__name">{{ item.name }}</div>
                  <div class="nav__desc">{{ item.description }}</div>
                </div>
              </a>
            </template>
          </template>
        </div>
      </div>
    </nav>
    <!-- END  navigation -->
  </div>
  <!-- END  container -->
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      menu_items: [
        {
          id: 'signup',
          name: 'Регистрация',
          description: 'Создание аккаунта',
          router: {
            name: 'Signup',
          },
          imgSrc: require('@/assets/images/navigation/ico-1.png'),
          alt: 'Создание аккаунта',
          isModal: true,
        },
        {
          id: 'files',
          name: 'Скачать',
          description: 'Игровые файлы',
          router: {
            name: 'Downloads',
          },
          imgSrc: require('@/assets/images/navigation/ico-2.png'),
          alt: 'Игровые файлы',
          isModal: true,
        },
        {
          id: 'support',
          name: 'Поддержка',
          description: 'Сервисы и помощь',
          router: {
            name: 'Support',
          },
          imgSrc: require('@/assets/images/navigation/ico-3.png'),
          alt: 'Сервисы и помощь',
          isModal: true,
        },
        {
          id: 'forum',
          name: 'Форум',
          description: 'Сообщество',
          router: {},
          link: 'https://forum.wowbless.com/',
          imgSrc: require('@/assets/images/navigation/ico-4.png'),
          alt: 'Сообщество',
          isModal: false,
        },
        {
          id: 'wiki',
          name: 'Wiki',
          description: 'База знаний',
          router: {},
          link: 'https://wotlk.evowow.com/',
          imgSrc: require('@/assets/images/navigation/ico-5.png'),
          alt: 'Wiki',
          isModal: false,
        },
        {
          id: 'shop',
          name: 'Магазин',
          description: 'Оплата',
          router: {
            name: 'Shop',
          },
          imgSrc: require('@/assets/images/navigation/ico-6.png'),
          alt: 'Оплата',
          isModal: true,
        },
      ],
    }
  },
}
</script>

<style scoped></style>
