<template>
  <div class="section" data-section="navigation">
    <div class="box">
      <a class="logo logo_pos logo_nav" href="/" title="WowBless">
        <img
          alt="WowBless"
          class="logo__img"
          src="@/assets/images/logo/logo.png"
        />
      </a>
      <Navigation />
      <div class="bar">
        <FunctionButton v-for="(btn, i) in buttons" :btn="btn" :key="i" />
        <div class="lang bar__item">
          <div
            class="lang__link"
            data-inner-select="languages"
            @click="this.$router.push('/lang')"
          >
            <img
              :src="generateLanguageIcon"
              :alt="flag"
              class="lang__img-current"
              data-current-lang
            />
          </div>
          <div class="lang__hint hint notranslate">
            <div class="hint__list hint__list_decor lang__list">
              <LanguageItem
                v-for="(language, i) in languages"
                :lang="language"
                :key="i"
                @click="change_lang(language)"
              />
            </div>
          </div>
        </div>
        <NavigationSwitcher />
      </div>
    </div>
  </div>
</template>

<script>
import FunctionButton from '@/components/Header/RightBar/FunctionButton'
import LanguageItem from '@/components/Header/RightBar/LanguageItem'
import Navigation from '@/components/Header/Navigation'
import NavigationSwitcher from '@/components/Header/RightBar/NavigationSwitcher'

export default {
  name: 'Header',
  components: { NavigationSwitcher, Navigation, LanguageItem, FunctionButton },
  data() {
    return {
      buttons: [
        {
          alt: 'search',
          src: require('@/assets/images/fbtn/ico-search.png'),
        },
        {
          alt: 'acc',
          src: require('@/assets/images/fbtn/ico-acc.png'),
        },
      ],
    }
  },
  methods: {
    change_lang(lang) {
      this.$store.dispatch('change_language', lang)
      window.location.reload()
    },
  },
  computed: {
    flag: {
      get() {
        try {
          return localStorage.flag
        } catch (e) {
          return this.$store.getters.flag
        }
      },
    },
    languages() {
      return this.$store.getters.languages_reverse
    },
    generateLanguageIcon() {
      return require(`@/assets/images/lang/lg__${this.flag}.png`)
    },
  },
  beforeCreate() {
    this.$store.commit('INIT_LANG')
  },
}
</script>

<style scoped></style>
