import { _axios } from '@/plugins/axios'

export default {
  actions: {
    update_csrf_header({ commit }, array) {
      commit('UPDATE_CSRF_KEY', array.key)
      commit('UPDATE_CSRF_TOKEN', array.value)
    },
    init_config({ commit }) {
      _axios
        .get('/api/v1/config/init')
        .then((response) => {
          _axios.defaults.xsrfHeaderName = response.data.xsrfHeaderName
          _axios.defaults.xsrfCookieName = response.data.xsrfCookieName
          _axios.defaults.headers[response.data.xsrfHeaderName] =
            response.data.xsrfToken
          commit('UPDATE_SERVERS', response.data.servers)
        })
        .catch((e) => {
          console.error(e.message)
        })
    },
    async init_forum({ dispatch }) {
      await _axios
        .get('/api/v1/forum')
        .then((res) => {
          dispatch('update_media_news', res.data.news)
          dispatch('update_media_events', res.data.events)
          dispatch('update_media_topics', res.data.topics)
        })
        .catch((e) => {
          console.error('init_forum [ERROR]:', e.message)
        })
    },
  },
  mutations: {
    UPDATE_PROJECT_NAME(state, value) {
      state.project_name = value
    },
    PRELOAD_FINISH(state) {
      state.preload.finished = true
    },
    UPDATE_CSRF_KEY(state, value) {
      state.csrf_token_name = value
    },
    UPDATE_CSRF_TOKEN(state, value) {
      state.csrf_token_value = value
    },
  },
  state: {
    project_name: 'WowBless',
    preload: {
      finished: false,
      time: 2,
      on_click_hide: true,
    },
    csrf_token_name: null,
    csrf_token_value: null,
  },
  getters: {
    project_name: (state) => state.project_name,
    cfg_preload: (state) => state.preload,
    preload_finished: (state) => state.preload.finished,
    csrf_token_name: (state) => state.csrf_token_name,
    csrf_token_value: (state) => state.csrf_token_value,
  },
}
