<template>
  <a class="item" href="#">
    <div class="item__date wk-box-center">{{ item.date }}</div>
    <div class="item__content">
      <div class="item__title">{{ item.title }}</div>
      <div class="item__desc">{{ item.description }}</div>
    </div>
    <div class="item__btns">
      <a :href="item.url" target="_blank">
        <div class="btn btn_size_small">
          <div class="btn__content">Подробнее</div>
        </div>
      </a>
    </div>
  </a>
</template>

<script>
export default {
  name: 'TabItem',
  props: ['item', 'ind'],
}
</script>

<style scoped></style>
