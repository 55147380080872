<template>
  <div class="modal" @click="modal_bg($event)">
    <div class="modal__inner">
      <div class="modal__heading-decoration"></div>
      <div class="modal__footer-decoration"></div>
      <div class="modal__close" @click="close_modal"></div>
      <div class="modal__content">
        <ModalSelector />
        <router-view v-slot="{ Component }">
          <KeepAlive>
            <component :is="Component" />
          </KeepAlive>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
// <KeepAlive>
// </KeepAlive>
import ModalSelector from '@/components/Modal/ModalSelector'
export default {
  name: 'Modal',
  components: { ModalSelector },
  methods: {
    modal_bg(event) {
      if (event.target.classList.contains('modal')) {
        this.$emit('close')
        this.$router.push('/')
      }
    },
    close_modal() {
      this.$emit('close')
      this.$router.push('/')
    },
  },
}
</script>

<style scoped>
.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from,
.modal-leave-to {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
