export default {
  actions: {
    update_media_news({ commit }, news) {
      commit('UPDATE_NEWS', news)
    },
    update_media_events({ commit }, events) {
      commit('UPDATE_EVENTS', events)
    },
    update_media_topics({ commit }, topics) {
      commit('UPDATE_TOPICS', topics)
    },
  },
  mutations: {
    UPDATE_NEWS(state, news) {
      state.news = news
    },
    UPDATE_EVENTS(state, events) {
      state.events = events
    },
    UPDATE_TOPICS(state, topics) {
      state.topics = topics
    },
    UPDATE_SOCIAL_ICONS(state, social_icons) {
      state.social_icons = social_icons
    },
    UPDATE_STREAMS(state, streams) {
      state.streams = streams
    },
    UPDATE_VIDEOS(state, videos) {
      state.videos = videos
    },
    UPDATE_GALLERY(state, gallery) {
      state.gallery = gallery
    },
  },
  state: {
    news: [],
    events: [],
    topics: [],
    social_icons: [],
    streams: [],
    videos: [],
    gallery: [],
    social_links: [
      {
        alt: 'googlePlus',
        href: 'googlePlus',
        imgSrc: require('@/assets/images/share/google-plus.png'),
      },
      {
        alt: 'twitter',
        href: 'twitter',
        imgSrc: require('@/assets/images/share/twitter.png'),
      },
      {
        alt: 'vk',
        href: 'vk',
        imgSrc: require('@/assets/images/share/vk.png'),
      },
      {
        alt: 'rss',
        href: 'rss',
        imgSrc: require('@/assets/images/share/rss.png'),
      },
      {
        alt: 'facebook',
        href: 'facebook',
        imgSrc: require('@/assets/images/share/facebook.png'),
      },
      {
        alt: 'ok-alt',
        href: 'ok',
        imgSrc: require('@/assets/images/share/ok.png'),
      },
    ],
  },
  /**
   * GETTERS
   */
  getters: {
    get_media_news(state) {
      return state.news
    },
    get_media_events(state) {
      return state.events
    },
    get_media_topics(state) {
      return state.topics
    },
    get_media_social_icons(state) {
      return state.social_icons
    },
    get_media_social_links(state) {
      return state.social_links
    },
    get_media_streams(state) {
      return state.streams
    },
    get_media_videos(state) {
      return state.videos
    },
    get_media_gallery(state) {
      return state.gallery
    },
  },
}
