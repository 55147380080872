export default {
  actions: {},
  mutations: {
    TOGGLE_FOOTER(state) {
      try {
        state.footer = !state.footer
        localStorage.footer = state.footer ? '1' : '0'
      } catch (e) {
        console.log('e', e)
      }
    },
    INIT_FOOTER(state) {
      try {
        if (localStorage.footer) {
          state.footer = localStorage.footer
        }
      } catch (e) {
        state.footer = true
      }
    },
  },
  state: {
    footer: true,
  },
  getters: {
    footer: (state) => state.footer,
  },
}
