<template>
  <div class="section" data-section="servers">
    <div class="container">
      <div class="servers">
        <ServerItem
          v-for="(server, i) in servers"
          :key="i"
          :server="server"
        ></ServerItem>
        <PlayButton />
      </div>
    </div>
  </div>
</template>

<script>
import ServerItem from '@/components/Body/Servers/ServerItem'
import PlayButton from '@/components/Body/Servers/PlayButton'
export default {
  name: 'Servers',
  components: { PlayButton, ServerItem },
  computed: {
    servers() {
      return this.$store.getters.servers
    },
    modal: {
      set(value) {
        this.$store.commit('UPDATE_MODAL', value)
      },
      get() {
        return this.$store.getters.show_modal
      },
    },
  },
}
</script>

<style scoped></style>
