<template>
  <a
    class="hint__item lang__item"
    :class="{
      hint__item_rr: lang.dataLang === 'ru',
      hint__item_rl: lang.dataLang === 'no',
    }"
    :data-google-lang="lang.dataLang"
    :title="lang.name"
    href="#"
  >
    <img
      :alt="lang.dataLang"
      class="lang__img"
      :src="generateImgSrc(lang.imgSuffix)"
    />
    <div class="hint__content">{{ lang.name }}</div>
  </a>
</template>

<script>
export default {
  name: 'LanguageItem',
  props: ['lang'],
  methods: {
    generateImgSrc(lang) {
      return require(`@/assets/images/lang/lg__${lang}.png`)
    },
  },
}
</script>

<style scoped></style>
