<template>
  <div class="page">
    <Preload />
    <Header />
    <Servers />
    <Wrapper />
    <Teleport to="body">
      <Transition name="modal">
        <Modal v-if="showModal" @close="showModal = false" />
      </Transition>
    </Teleport>
  </div>
</template>

<script>
import Preload from '@/components/Body/Preload'
import Header from '@/components/Header/Header'
import Servers from '@/components/Body/Servers/Servers'
import Wrapper from '@/components/Body/Wrapper'
import Modal from '@/components/Modal'

import '@/assets/libs/select2/js/select2.full.min.407'
import '@/assets/libs/select2/css/select2.min.latest.css'

export default {
  name: 'App',
  components: { Modal, Wrapper, Servers, Header, Preload },
  computed: {
    showModal: {
      set(value) {
        this.$store.commit('UPDATE_MODAL', value)
      },
      get() {
        return this.$store.getters.show_modal
      },
    },
  },
  watch: {
    $route(to) {
      const is_modal = this.$router
        .getRoutes()
        .filter((route) => route.path === to.path && to.path !== '/')
      if (is_modal.length) {
        if (!this.$store.getters.cfg_preload.finished) {
          setTimeout(() => {
            this.showModal = true
          }, 2000)
        } else {
          this.showModal = true
        }
      } else {
        this.showModal = false
      }
    },
  },
}
</script>

<style>
@import 'assets/fonts/AlbertusExtraBold/stylesheet.css';
@import 'assets/fonts/AlegreyaSansSC/stylesheet.css';
@import 'assets/libs/gwi/css/gwi.css';
@import 'assets/css/main.css';

a {
  text-decoration: none;
}

.page {
  overflow: hidden !important;
}

iframe {
  display: none !important;
}
</style>
