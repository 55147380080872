<template>
  <footer class="section" data-section="footer">
    <div class="container" data-container="footer" data-toggle-footer-container>
      <div class="footer">
        <div class="footer__nav">
          <div class="f-nav footer__f-nav">
            <div class="footer__title">Сервисы</div>
            <div class="f-nav__list">
              <router-link class="f-nav__item" :to="{ name: 'RestorePassword' }"
                >Восстановление пароля</router-link
              >
              <router-link class="f-nav__item" :to="{ name: 'ChangePassword' }"
                >Смена пароля</router-link
              >
              <router-link class="f-nav__item" :to="{ name: 'RestoreUsername' }"
                >Восстановление логина</router-link
              >
              <router-link class="f-nav__item" :to="{ name: 'ChangeEmail' }"
                >Смена E-mail</router-link
              >
              <router-link class="f-nav__item" :to="{ name: 'Unbind' }"
                >Снять привязку по HWID/IP</router-link
              >
            </div>
          </div>
          <div class="f-nav footer__f-nav">
            <div class="footer__title">Поддержка</div>
            <div class="f-nav__list">
              <router-link class="f-nav__item" :to="{ name: 'Contacts' }"
                >Контакты</router-link
              >
              <a class="f-nav__item" href="#">Faq</a>
              <a class="f-nav__item" href="#">Информация</a>
              <router-link class="f-nav__item" :to="{ name: 'Vote' }"
                >Проголосовать</router-link
              >
              <router-link class="f-nav__item" :to="{ name: 'Shop' }"
                >Магазин</router-link
              >
            </div>
          </div>
          <div class="f-nav footer__f-nav">
            <div class="footer__title">Больше</div>
            <div class="f-nav__list">
              <router-link class="f-nav__item" :to="{ name: 'Rules' }"
                >Правила игры</router-link
              >
              <router-link class="f-nav__item" :to="{ name: 'UserAgreement' }"
                >Пользовательское соглашение</router-link
              >
              <router-link
                class="f-nav__item"
                :to="{ name: 'LicenseAgreement' }"
                >Лицензионное соглашение</router-link
              >
              <router-link class="f-nav__item" :to="{ name: 'G2AAgreement' }"
                >Дополнительные условия g2a</router-link
              >
              <router-link class="f-nav__item" :to="{ name: 'Statistics' }"
                >Статистика</router-link
              >
            </div>
          </div>
        </div>
        <!-- END  footer__nav -->
        <div class="footer__info">
          <div class="copyright">
            <!--            <div class="copyright__bnrs">-->
            <!--              <div class="copyright__bnr">-->
            <!--                <img-->
            <!--                  alt="bnr-1"-->
            <!--                  class="copyright__img"-->
            <!--                  src="@/assets/images/copyright/bnr-1.jpg"-->
            <!--                />-->
            <!--              </div>-->
            <!--              <div class="copyright__bnr">-->
            <!--                <img-->
            <!--                  alt="bnr-2"-->
            <!--                  class="copyright__img"-->
            <!--                  src="@/assets/images/copyright/bnr-2.jpg"-->
            <!--                />-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="copyright__txt">
              <span class="copyright__site">© 2022 WOWBLESS </span>
            </div>
            <router-link
              class="copyright__policy"
              :to="{ name: 'PrivacyPolicy' }"
              >ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped></style>
