<template>
  <div v-show="is_service.length" class="field field_grp field_mb">
    <div class="field__desc">
      <div class="field__ico">
        <img
          alt=""
          src="@/assets/images/icons/ico__page.png"
          style="width: 2.2rem; height: 2.8rem"
        />
      </div>
      <div class="field__title">Страница</div>
    </div>
    <select
      class="tt-uppercase"
      data-services-list
      data-track-stab
      name="page"
      v-model="selected"
      @change="change_selector(this.selected)"
    >
      <option v-for="(route, i) in routes" :value="route.value" :key="i">
        {{ route.title }}
      </option>
    </select>
  </div>
</template>

<script>
// :selected="route.value === this.$route.path.split('/')[1]"

export default {
  name: 'ModalSelector',
  data() {
    return {
      selected: this.$route.path.split('/')[1],
      routes: [
        {
          title: 'Регистрация',
          value: 'signup',
        },
        {
          title: 'Скачать файлы',
          value: 'files',
        },
        {
          title: 'Магазин',
          value: 'shop',
        },
        {
          title: 'Сменить пароль',
          value: 'change_password',
        },
        {
          title: 'Восстановить пароль',
          value: 'restore_password',
        },
        {
          title: 'Сменить E-mail',
          value: 'change_email',
        },
        {
          title: 'Снять привязку по HWID/IP',
          value: 'unbind',
        },
        {
          title: 'Восстановить логин',
          value: 'restore_username',
        },
        {
          title: 'Голосовать',
          value: 'vote',
        },
        {
          title: 'Контакты',
          value: 'contacts',
        },
        {
          title: 'Поддержка',
          value: 'support',
        },
        {
          title: 'LIVE STREAM/ВИДЕО/ГАЛЕРЕЯ',
          value: 'media',
        },
        {
          title: 'Языки',
          value: 'lang',
        },
        {
          title: 'Сервер',
          value: 'server',
        },
      ],
    }
  },
  computed: {
    is_service() {
      return this.routes.filter((route) => route.value === this.route_path)
    },
    route_path() {
      return this.$route.path.split('/')[1]
    },
  },
  methods: {
    change_selector(path) {
      this.$router.push('/' + path)
    },
  },
  updated() {
    if (this.selected !== this.route_path) {
      this.selected = this.route_path
    }
  },
}
</script>

<style>
.modal__content form {
  transition: all 0.3s ease;
}
</style>
