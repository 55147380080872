const $ = require('jquery')
import '@/assets/libs/jquery.cookie/jquery.cookie.min'

export default {
  actions: {
    change_language({ commit, state }, lang) {
      if (typeof lang !== 'object') {
        lang = state.languages.filter((o) => o.imgSuffix === lang)[0]
      }
      commit('UPDATE_LANG', lang)
    },
  },
  mutations: {
    INIT_LANG(state) {
      let def_lang_obj = state.languages.filter(
        (o) => o.imgSuffix === state.default_lang
      )[0]
      try {
        if (!localStorage.lang) {
          this.commit('UPDATE_LANG', def_lang_obj)
          localStorage.lang = state.default_lang
        } else {
          if (localStorage.flag) {
            def_lang_obj = state.languages.filter(
              (o) => o.imgSuffix === localStorage.flag
            )[0]
          } else {
            def_lang_obj = state.languages.filter(
              (o) => o.imgSuffix === localStorage.lang
            )[0]
          }
          this.commit('UPDATE_LANG', def_lang_obj)
        }
      } catch (e) {
        def_lang_obj = state.languages.filter(
          (o) => o.imgSuffix === state.origin_lang
        )[0]
        this.commit('UPDATE_LANG', def_lang_obj)
      }
    },
    UPDATE_LANG(state, lang) {
      this.commit('UPDATE_FLAG', lang.imgSuffix)

      try {
        localStorage.lang = lang.dataLang
        state.lang = lang.dataLang
      } catch (e) {
        state.lang = lang.dataLang
      }

      lang =
        lang.dataLang === state.origin_lang
          ? null
          : lang.dataLang === 'zh'
          ? '/' + state.origin_lang + '/zh-CN'
          : '/' + state.origin_lang + '/' + lang.dataLang

      $.cookie('googtrans', lang)
      $.cookie('googtrans', lang, {
        domain: '.' + document.domain,
      })
    },
    UPDATE_FLAG(state, flag) {
      try {
        localStorage.flag = flag
        state.flag = flag
      } catch (e) {
        state.flag = flag
      }
    },
  },
  getters: {
    lang: (state) => state.lang,
    default_lang: (state) => state.default_lang,
    origin_lang: (state) => state.origin_lang,
    flag: (state) => state.flag,
    languages: (state) => state.languages,
    languages_reverse: (state) => state.languages.slice().reverse(),
  },
  state: {
    origin_lang: 'ru',
    default_lang: 'en',
    lang: 'ru',
    flag: null,
    languages: [
      {
        dataLang: 'lv',
        imgSuffix: 'lv',
        name: 'Latviešu',
      },
      {
        dataLang: 'sv',
        imgSuffix: 'sv',
        name: 'Svenska',
      },
      {
        dataLang: 'az',
        imgSuffix: 'az',
        name: 'Azərbaycan',
      },
      {
        dataLang: 'pt',
        imgSuffix: 'bras',
        name: 'Brasileiro',
      },
      {
        dataLang: 'de',
        imgSuffix: 'de',
        name: 'Deutsch',
      },
      {
        dataLang: 'hy',
        imgSuffix: 'hy',
        name: 'Hայերեն',
      },
      {
        dataLang: 'en',
        imgSuffix: 'usa',
        name: 'American',
      },
      {
        dataLang: 'et',
        imgSuffix: 'et',
        name: 'Eestlane',
      },
      {
        dataLang: 'da',
        imgSuffix: 'da',
        name: 'Dansk',
      },
      {
        dataLang: 'pt',
        imgSuffix: 'pt',
        name: 'Português',
      },
      {
        dataLang: 'uk',
        imgSuffix: 'uk',
        name: 'Українська',
      },
      {
        dataLang: 'fr',
        imgSuffix: 'fr',
        name: 'Français',
      },
      {
        dataLang: 'es',
        imgSuffix: 'es',
        name: 'Español',
      },
      {
        dataLang: 'pl',
        imgSuffix: 'pl',
        name: 'Polski',
      },
      {
        dataLang: 'ja',
        imgSuffix: 'ja',
        name: '日本語',
      },
      {
        dataLang: 'el',
        imgSuffix: 'el',
        name: 'Ελληνικά',
      },
      {
        dataLang: 'cs',
        imgSuffix: 'cs',
        name: 'Češka',
      },
      {
        dataLang: 'ko',
        imgSuffix: 'ko',
        name: '한국어',
      },
      {
        dataLang: 'en',
        imgSuffix: 'en',
        name: 'English',
      },
      {
        dataLang: 'tr',
        imgSuffix: 'tr',
        name: 'Türk',
      },
      {
        dataLang: 'zh',
        imgSuffix: 'zh',
        name: '中文',
      },
      {
        dataLang: 'ru',
        imgSuffix: 'ru',
        name: 'Русский',
      },
      {
        dataLang: 'lt',
        imgSuffix: 'lt',
        name: 'Lietuvos',
      },
      {
        dataLang: 'no',
        imgSuffix: 'no',
        name: 'Norsk',
      },
    ],
  },
}
