import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('@/views/Main'),
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/components/Modal/Signup'),
  },
  {
    path: '/files',
    name: 'Downloads',
    component: () => import('@/components/Modal/Downloads'),
  },
  {
    path: '/vote',
    name: 'Vote',
    component: () => import('@/components/Modal/Vote'),
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('@/components/Modal/Shop'),
  },
  {
    path: '/stats',
    name: 'Statistics',
    component: () => import('@/components/Modal/Statistics'),
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('@/components/Modal/Support'),
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('@/components/Modal/Contacts'),
  },
  {
    path: '/server',
    name: 'Server',
    component: () => import('@/components/Modal/Server'),
  },
  {
    path: '/unbind',
    name: 'Unbind',
    component: () => import('@/components/Modal/Unbind'),
  },
  {
    path: '/lang',
    name: 'Language',
    component: () => import('@/components/Modal/Language'),
  },
  {
    path: '/restore_password',
    name: 'RestorePassword',
    component: () => import('@/components/Modal/RestorePassword'),
  },
  {
    path: '/restore_username',
    name: 'RestoreUsername',
    component: () => import('@/components/Modal/RestoreUsername'),
  },
  {
    path: '/change_password',
    name: 'ChangePassword',
    component: () => import('@/components/Modal/ChangePassword'),
  },
  {
    path: '/change_email',
    name: 'ChangeEmail',
    component: () => import('@/components/Modal/ChangeEmail'),
  },
  {
    path: '/media',
    name: 'Media',
    component: () => import('@/components/Modal/Media'),
  },
  {
    path: '/rules',
    name: 'Rules',
    component: () => import('@/components/Modal/Rules'),
  },
  {
    path: '/user_agreement',
    name: 'UserAgreement',
    component: () => import('@/components/Modal/UserAgreement'),
  },
  {
    path: '/license_agreement',
    name: 'LicenseAgreement',
    component: () => import('@/components/Modal/LicenseAgreement'),
  },
  {
    path: '/g2a_agreement',
    name: 'G2AAgreement',
    component: () => import('@/components/Modal/G2AAgreement'),
  },
  {
    path: '/privacy_policy',
    name: 'PrivacyPolicy',
    component: () => import('@/components/Modal/PrivacyPolicy'),
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
