<template>
  <div
    class="streams__stream stream"
    :class="{ stream_fullscreen: stream.isFullscreen }"
    :data-insertmedia="stream.isActive && JSON.stringify(stream.options)"
  >
    <div class="stream__content">
      Тут мог быть ваш стрим <br />
      <router-link href="#" :to="{ name: 'Media' }" class="stream__link"
        >Добавить</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'StreamItem',
  props: ['stream'],
}
</script>

<style scoped></style>
