<template>
  <div class="section" data-section="under">
    <div class="footer-switcher" data-toggle-footer @click="toggle_footer">
      <div class="hint">
        <div class="hint__btn hint__btn_bg hint__btn_cursor_pointer">
          <div
            class="hint__arrow hint__arrow_size_small footer-switcher__arrow"
          ></div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="logotype logotype_small">
        <a class="logo logo_pos logo_footer" href="/" title="WowBless">
          <img
            alt="WowBless"
            class="logo__img"
            src="@/assets/images/logo/logo.png"
          />
        </a>
      </div>
      <div class="container">
        <div class="under">
          <div class="under__ps ps">
            <div v-for="(ps, i) in payments_systems" :key="i" class="ps__item">
              <img
                :alt="ps.alt"
                class="ps__img"
                :class="ps.class"
                :src="ps.src"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const $ = require('jquery')

export default {
  name: 'Bottom',
  data() {
    return {
      speed: 300,
      payments_systems: [
        {
          alt: 'paypal',
          class: 'ps__img_paypal',
          src: require('@/assets/images/ps/paypal.png'),
        },
        {
          alt: 'g2a',
          class: 'ps__img_g2a',
          src: require('@/assets/images/ps/g2a.png'),
        },
        {
          alt: 'paymentwall',
          class: 'ps__img_paymentwall',
          src: require('@/assets/images/ps/paymentwall.png'),
        },
        {
          alt: 'freekassa',
          class: 'ps__img_freekassa',
          src: require('@/assets/images/ps/freekassa.png'),
        },
        {
          alt: 'payop',
          class: 'ps__img_payop',
          src: require('@/assets/images/ps/payop.png'),
        },
      ],
    }
  },
  methods: {
    toggle_footer() {
      $('[data-toggle-footer-container]').slideToggle(this.speed, function () {
        try {
          let footer = localStorage.getItem('footer').toString()
          localStorage.setItem('footer', footer === '1' ? '0' : '1')
        } catch (e) {
          console.warn(e.message)
        }
        $('body').toggleClass('footer-hide')
        $(window).trigger('resize')
      })
    },
  },
  mounted() {
    try {
      let _footer = localStorage.getItem('footer').toString()

      if (_footer === '1') {
        $('[data-toggle-footer-container]').slideUp(this.speed, function () {
          $('body').toggleClass('footer-hide')
          $(window).trigger('resize')
        })
      }

      if (
        _footer !== '0' &&
        _footer !== '1' &&
        document.body.clientWidth < 1100
      ) {
        $('[data-toggle-footer-container]').slideToggle(
          this.speed,
          function () {
            $('body').toggleClass('footer-hide')
            $(window).trigger('resize')
          }
        )
      }
    } catch (e) {
      if (document.body.clientWidth < 1100) {
        $('[data-toggle-footer-container]').slideToggle(
          this.speed,
          function () {
            $('body').toggleClass('footer-hide')
            $(window).trigger('resize')
          }
        )
      }
    }
  },
}
</script>

<style scoped></style>
