import { createStore } from 'vuex'
import app from '@/store/modules/app'
import lang from '@/store/modules/lang'
import server from '@/store/modules/server'
import footer from '@/store/modules/footer'
import modal from '@/store/modules/modal'
import media from '@/store/modules/media'

export default createStore({
  actions: {},
  mutations: {},
  state: {},
  getters: {},
  modules: {
    app,
    modal,
    lang,
    server,
    footer,
    media,
  },
})
