<template>
  <div class="mid__slider mid__item">
    <div class="snews">
      <swiper
        ref="swiper"
        :slides-per-view="1"
        :space-between="10"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        @init="onInit"
        :autoplay="params.autoplay"
        :navigation="params.navigation"
        :pagination="params.pagination"
        preloadImages
        updateOnImagesReady
      >
        <swiper-slide
          class="snews__slide"
          v-for="(slide, index) in news"
          :key="index"
          :style="{ backgroundImage: `url('${slide.img}')` }"
        >
          <div class="snews__inner">
            <div class="news_timestamp">{{ slide.date }}</div>
            <h2 class="snews__title">{{ slide.title }}</h2>
            <a class="snews__btn btn" target="_blank" :href="slide.url">
              <div class="btn__content">Подробнее</div>
            </a>
            <div class="snews__share share">
              <a class="share__item" href="#">
                <img
                  alt=""
                  class="share__img"
                  src="@/assets/images/share/twitter.png"
                />
              </a>
              <a class="share__item" href="#">
                <img
                  alt=""
                  class="share__img"
                  src="@/assets/images/share/vk.png"
                />
              </a>
              <a class="share__item" href="#">
                <img
                  alt=""
                  class="share__img"
                  src="@/assets/images/share/rss.png"
                />
              </a>
              <a class="share__item" href="#">
                <img
                  alt=""
                  class="share__img"
                  src="@/assets/images/share/facebook.png"
                />
              </a>
              <a class="share__item" href="#">
                <img
                  alt=""
                  class="share__img"
                  src="@/assets/images/share/ok.png"
                />
              </a>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="pag" data-slider-pagination="snews"></div>
      <div class="snews__controls">
        <div class="snews__arrows arrows">
          <div class="arrows__arrow arrows__arrow_prev" data-snews-prev>
            <div class="slider-prev"></div>
          </div>
          <div class="snews__counter" ref="slider_counter"></div>
          <div class="arrows__arrow arrows__arrow_next" data-snews-next>
            <div class="slider-next"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import '@/assets/libs/swiper/css/swiper.min.css'

SwiperCore.use([Navigation, Autoplay, Pagination])

export default {
  name: 'Slider',
  components: { Swiper, SwiperSlide },
  data() {
    return {
      slide_counter: '1/1',
      params: {
        init: true,
        navigation: {
          nextEl: '[data-snews-next]',
          prevEl: '[data-snews-prev]',
        },
        pagination: {
          el: '[data-slider-pagination="snews"]',
          bulletClass: 'pag__bullet',
          clickable: true,
        },
        autoplay: {
          enabled: true,
          delay: 10000,
          disableOnInteraction: true,
        },
      },
    }
  },
  computed: {
    news() {
      return this.$store.getters.get_media_news
    },
    swiper() {
      return this.$refs.swiper
    },
  },
  methods: {
    onSwiper(swiper) {
      swiper.$el.on('mouseenter', function () {
        swiper.autoplay.stop()
      })
      swiper.$el.on('mouseleave', function () {
        swiper.autoplay.start()
      })
    },
    onInit(swiper) {
      this.update_slide_counter(swiper)
    },
    onSlideChange(swiper) {
      this.update_slide_counter(swiper)
    },
    update_slide_counter(swiper) {
      this.$refs.slider_counter.innerHTML = `${swiper.activeIndex + 1}/${
        swiper.slides.length || 3
      }`
    },
  },
}
</script>

<style scoped>
.news_timestamp {
  bottom: 3.5em;
  right: 0;
  padding: 5px 10px;
  color: white;
  font-family: 'gwi', sans-serif;
  font-size: 1.6em;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
}
</style>
