<template>
  <div class="mid__content mid__item">
    <div class="tabs">
      <div class="tabs__btns">
        <div
          class="tabs__btn tab-block"
          :class="{ tabs__btn_active: active_tab === i }"
          v-for="(tab, i) in tabs"
          :key="i"
          @click="active_tab = i"
        >
          {{ tab.name }}
        </div>
        <router-link
          :to="{ name: 'Statistics' }"
          class="tabs__btn tab-block tabs__btn_link"
          href="./"
          >Статистика</router-link
        >
      </div>
      <div class="tabs__content">
        <div class="tabs__tab" :class="{ 'display-block': active_tab === 0 }">
          <div class="items">
            <div class="items__list items__list_short">
              <TabItem
                v-for="(article, index) in news"
                :key="index"
                :item="article"
              ></TabItem>
            </div>
            <div class="tabs__more">
              <a class="btn btn_size_large" href="#">
                <div class="btn__content">Больше новостей</div>
              </a>
            </div>
          </div>
        </div>
        <div class="tabs__tab" :class="{ 'display-block': active_tab === 1 }">
          <div class="items">
            <div class="items__list items__list_short">
              <TabItem
                v-for="(event, index) in events"
                :key="index"
                :item="event"
              ></TabItem>
            </div>
            <div class="tabs__more">
              <a class="btn btn_size_large" href="#">
                <div class="btn__content">Больше событий</div>
              </a>
            </div>
          </div>
        </div>
        <div class="tabs__tab" :class="{ 'display-block': active_tab === 2 }">
          <div class="items">
            <div class="items__list items__list_short">
              <TabItem
                v-for="(topic, index) in topics"
                :key="index"
                :item="topic"
              ></TabItem>
            </div>
            <div class="tabs__more">
              <a class="btn btn_size_large" href="#">
                <div class="btn__content">Перейти на форум</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabItem from '@/components/Body/Tabs/TabItem'

export default {
  name: 'Tabs',
  components: { TabItem },
  data() {
    return {
      active_tab: 0,
      tabs: [
        {
          name: 'Новости',
        },
        {
          name: 'Эвенты',
        },
        {
          name: 'Форум',
        },
      ],
      tab_content: [],
    }
  },
  computed: {
    news: {
      get() {
        return this.$store.getters.get_media_news
      },
      set(value) {
        this.$store.dispatch('update_media_news', value)
      },
    },
    events: {
      get() {
        return this.$store.getters.get_media_events
      },
      set(value) {
        this.$store.dispatch('update_media_events', value)
      },
    },
    topics: {
      get() {
        return this.$store.getters.get_media_topics
      },
      set(value) {
        this.$store.dispatch('update_media_topics', value)
      },
    },
  },
}
</script>

<style scoped>
.display-block {
  display: block;
}
</style>
