<template>
  <div class="servers__btn-box">
    <div class="start hint-hover">
      <router-link :to="{ name: 'Signup' }">
        <div class="start__btn">Играть</div>
      </router-link>
      <div class="hint hint_size_large start__hint">
        <div class="hint__btn">
          <div class="hint__arrow"></div>
        </div>
        <div class="hint__list hint__list_arrow">
          <router-link
            :to="{ name: 'Signup' }"
            class="hint__item hint__item_rl hint__item_rr"
          >
            <div class="hint__content">Регистрация</div>
          </router-link>
          <router-link :to="{ name: 'Downloads' }" class="hint__item">
            <div class="hint__content">Скачать игру</div>
          </router-link>
        </div>
      </div>
      <!-- END hint -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayButton',
}
</script>

<style scoped></style>
