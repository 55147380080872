<template>
  <div class="gw-burger" @click="menu_switcher" data-menu-switcher>
    <div class="gw-burger__open"></div>
    <div class="gw-burger__close"></div>
  </div>
</template>

<script>
export default {
  name: 'NavigationSwitcher',
  methods: {
    scroll_go(target, behavior, offset) {
      let el = document.querySelector(target ?? 'body')
      if (!el) return
      let coords = el.getBoundingClientRect()
      window.scrollTo({
        top: coords.top + window.scrollY + parseInt(offset ?? '0'),
        behavior: behavior ?? 'smooth',
      })
    },
    menu_switcher() {
      if (document.body.clientWidth >= 650) {
        this.scroll_go('body', 'instant')
      }
      document.body.classList.toggle('mob-nav-active')
    },
  },
  mounted() {
    let resizeTimeoutNav
    window.addEventListener(
      'resize',
      function () {
        clearTimeout(resizeTimeoutNav)
        resizeTimeoutNav = setTimeout(function () {
          if (document.body.clientWidth >= 1300) {
            document.body.classList.remove('mob-nav-active')

            let logo = document.querySelector('.logo_pos')
            let mid = document.querySelector('[data-container="mid"]')
            if (!logo || !mid) return
            if (
              logo.getBoundingClientRect().right >
              mid.getBoundingClientRect().left
            ) {
              document.body.classList.add('adaptive-portrait')
            } else {
              document.body.classList.remove('adaptive-portrait')
            }
          } else {
            document.body.classList.remove('adaptive-portrait')
          }
        }, 100)
      },
      true
    )
    setTimeout(() => {
      try {
        window.dispatchEvent(new Event('resize'))
      } catch (error) {
        const resizeEvent = window.document.createEvent('UIEvents')
        resizeEvent.initEvent('resize', true, false)
        window.dispatchEvent(resizeEvent)
      }
    }, 100)
  },
}
</script>

<style scoped></style>
