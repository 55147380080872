<template>
  <div class="preload" ref="preload" @click="preload_fade">
    <img
      alt="preload"
      class="preload__logo"
      src="@/assets/images/preload/preload.png"
    />
  </div>
</template>

<script>
import { insertMedia } from '@/assets/js/insertmedia'
import { mapGetters } from 'vuex'

export default {
  name: 'Preload',
  data() {
    return {
      preload_clicked: false,
    }
  },
  computed: {
    ...mapGetters(['cfg_preload']),
  },
  methods: {
    preload_fade(e) {
      if (e) {
        if (!this.cfg_preload.on_click_hide || this.preload_clicked) {
          return false
        }
        this.preload_clicked = true
      }
      this.$refs.preload.classList.add('preload__fade')
      this.$refs.preload.style.opacity = '0'
      this.$store.commit('PRELOAD_FINISH')
    },
  },
  beforeMount() {
    this.$store.dispatch('init_config')
    this.$store.dispatch('init_forum')
  },
  mounted() {
    insertMedia({ delay: 1 })
    setTimeout(() => this.preload_fade(), this.cfg_preload.time * 1000)
  },
}
</script>

<style scoped></style>
