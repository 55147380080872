<template>
  <a class="fbtn bar__item" href="#">
    <div class="fbtn__pic">
      <img :alt="btn.alt" class="fbtn__img" :src="btn.src" />
    </div>
  </a>
</template>

<script>
export default {
  name: 'FunctionButton',
  props: ['btn'],
}
</script>

<style scoped></style>
