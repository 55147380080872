<template>
  <div class="wrapper">
    <div class="section" data-section="mid">
      <div class="container" data-container="mid">
        <div class="mid">
          <div class="mid__box">
            <Slider />
            <Tabs />
          </div>
          <Streams />
          <Social />
        </div>
      </div>
    </div>
    <Footer />
    <Bottom />
  </div>
</template>

<script>
import Slider from '@/components/Body/Slider/Slider'
import Tabs from '@/components/Body/Tabs/Tabs'
import Streams from '@/components/Body/Stream/Streams'
import Social from '@/components/Body/Social/Social'
import Bottom from '@/components/Footer/Bottom'
import Footer from '@/components/Footer/Footer'

export default {
  name: 'Wrapper',
  components: { Footer, Bottom, Social, Streams, Tabs, Slider },
}
</script>

<style scoped></style>
