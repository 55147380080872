import axios from 'axios'

const _axios = axios.create({
  baseURL: 'https://api.wowbless.com/',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
  xsrfCookieName: 'x-csrf-cookie',
  xsrfHeaderName: 'x-csrf-header',
})

export { _axios }
