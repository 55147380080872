<template>
  <div class="streams">
    <div class="streams__stream stream">
      <div
        class="stream__add"
        @click="this.$router.push({ name: 'Media' })"
      ></div>
    </div>

    <StreamItem
      v-for="(stream, i) in streams"
      :stream="stream"
      :key="i"
    ></StreamItem>
  </div>
</template>

<script>
import StreamItem from '@/components/Body/Stream/StreamItem'

// <!-- Twitch example: указываем ид канала -->
// <!-- <div class="streams__stream stream" data-insertmedia='{"type" : "twitch" , "src" : "https://player.twitch.tv/?channel=doublefine" , "setting" : "autoplay=true&muted=true&parent=l2banners.ru" }'>
//   <div class="stream__content">Тут мог быть ваш стрим <br>
//     <a href="#" data-inner-select="media>add" class="stream__link">Добавить</a>
//   </div>
// </div> -->
//
// <!-- start Youtube embed video, указываем ид видео  -->
// <!-- <div class="streams__stream stream" data-insertmedia='{"type" : "youtube", "src" : "https://www.youtube.com/embed/1crzeIw8WQg", "setting" : "autoplay=1&mute=1" }'>
//   <div class="stream__content">Тут мог быть ваш стрим <br>
//     <a href="#" data-inner-select="media>add" class="stream__link">Добавить</a>
//   </div>
// </div> -->

export default {
  name: 'Streams',
  components: { StreamItem },
  data() {
    return {
      streams: [
        {
          isStream: true,
          isActive: false,
          isFullscreen: false,
          options: {
            type: 'youtube',
            src: 'https://www.youtube.com/embed/jAJedktkD-k',
            setting: 'mute=1',
          },
        },
        {
          isStream: true,
          isActive: false,
          isFullscreen: false,
          options: {
            type: 'youtube',
            src: 'https://www.youtube.com/embed/ulVm17jxp74',
            setting: 'mute=1',
          },
        },
        {
          isStream: true,
          isActive: false,
          isFullscreen: false,
          options: {
            type: 'youtube',
            src: 'https://www.youtube.com/embed/xWkpk-G5rKw',
            setting: 'mute=1',
          },
        },
        {
          isStream: true,
          isActive: false,
          isFullscreen: false,
          options: {
            type: 'youtube',
            src: 'https://www.youtube.com/embed/5eYYUFyCc00',
            setting: 'mute=1',
          },
        },
      ],
    }
  },
}
</script>

<style scoped></style>
