export default {
  actions: {},
  mutations: {
    UPDATE_SERVERS(state, servers) {
      state.servers = servers
    },
  },
  state: {
    servers: [
      {
        id: 1,
        name: 'frostwyrm',
        rate: 5,
        status: 'off',
        online: 0,
        start_date: '2022-04-30T20:00:00+03:00',
      },
      {
        id: 2,
        name: 'icecrown',
        rate: 5,
        status: 'off',
        online: 0,
        start_date: '2022-10-01T20:00:00+03:00',
      },
    ],
    server_links: [
      {
        id: 'signup',
        name: 'Играть',
        routeName: 'Signup',
        class: 'hint__item hint__item_rl hint__item_rr',
        external: false,
        href: false,
      },
      {
        id: 'description',
        name: 'Описание',
        routeName: 'Description',
        class: 'hint__item',
        external: true,
        href: 'https://forum.wowbless.com/',
      },
      {
        id: 'vote',
        name: 'Проголосовать',
        routeName: 'Vote',
        class: 'hint__item',
        external: false,
        href: false,
      },
      {
        id: 'stats',
        name: 'Статистика',
        routeName: 'Statistics',
        class: 'hint__item',
        external: false,
        href: false,
      },
      {
        id: 'support',
        name: 'Поддержка',
        routeName: 'Support',
        class: 'hint__item',
        external: false,
        href: false,
      },
      {
        id: 'shop',
        name: 'Магазин',
        routeName: 'Shop',
        class: 'hint__item',
        external: false,
        href: false,
      },
    ],
  },
  getters: {
    servers: (state) => state.servers,
    server_links: (state) => state.server_links,
  },
}
