<template>
  <div
    class="hint-hover server"
    :class="{
      server_status_on: server.status === 'on',
      server_status_off: server.status === 'off',
    }"
    ref="server"
  >
    <router-link :to="{ name: 'Server' }" class="server__link" />
    <div class="server__status notranslate">
      <div class="server__state_online">
        {{ server.status === 'on' ? 'online' : 'offline' }}
      </div>
    </div>
    <div class="server__progress" :data-online="server.online"></div>
    <div class="server__content">
      <div class="server__front">
        <div class="server__life life notranslate" data-start-time>
          <div
            class="life__title"
            :class="{
              life__title_new: displayTitle !== lifeTimeAbbr,
              life__title_soon: displayDays > 30,
            }"
          >
            {{ displayTitle }}
          </div>
          <div class="life__days">
            {{
              displayDays === 1 ? displayDays + ' day' : displayDays + ' days'
            }}
          </div>
          <div class="life__time">
            {{ displayHours + ':' + displayMinutes + ':' + displaySeconds }}
          </div>
        </div>
        <div class="server__title notranslate">
          {{ server.name }}
          <span class="server__name">x{{ server.rate }}</span>
        </div>
      </div>
      <div class="server__back">
        <div class="server__title">Online</div>
        <div class="server__online notranslate">{{ server.online }}</div>
      </div>
    </div>
    <div class="server__hint hint">
      <div class="hint__btn">
        <div class="hint__arrow hint__arrow_size_small"></div>
      </div>
      <div class="hint__list hint_size_large">
        <ServerLink
          v-for="(link, i) in server_links"
          :key="i"
          :link="link"
          :server_id="server.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ServerLink from '@/components/Body/Servers/ServerLink'

import $ from 'jquery'
import '@/assets/libs/circle-progress/js/circle-progress.min'

export default {
  name: 'ServerItem',
  components: { ServerLink },
  props: ['server'],
  data() {
    return {
      circle_initiated: false,
      displayDays: 0,
      displayHours: 0,
      displayMinutes: 0,
      displaySeconds: 0,
      displayTitle: null,
      lifeTimeAbbr: 'Life Time',
      progress_img: require('@/assets/images/servers/server__load_progress_on.png'),
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    }
  },
  mounted() {
    this.showRemaining()

    if (!this.circle_initiated) {
      setTimeout(() => this.init_circle_progress(), 500)
    }
    this.circle_initiated = true
  },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60
    },
    _hours() {
      return this._minutes * 60
    },
    _days() {
      return this._hours * 24
    },
    server_links() {
      return this.$store.getters.server_links
    },
  },
  methods: {
    minutes_format: (num) => (num < 10 ? '0' + num : num),
    showRemaining() {
      setInterval(() => {
        const now = new Date()
        const end = new Date(this.server.start_date)
        const started = now.getTime() > end.getTime()

        this.displayTitle = !started
          ? this.monthNames[end.getMonth()] + ' ' + end.getDate()
          : this.lifeTimeAbbr

        const distance = Math.abs(end.getTime() - now.getTime())

        const days = Math.floor(distance / this._days)
        const hours = Math.floor((distance % this._days) / this._hours)
        const minutes = Math.floor((distance % this._hours) / this._minutes)
        const seconds = Math.floor((distance % this._minutes) / this._seconds)

        this.displayDays = days
        this.displayHours = this.minutes_format(hours)
        this.displayMinutes = this.minutes_format(minutes)
        this.displaySeconds = this.minutes_format(seconds)
      }, 1000)
    },
    init_circle_progress() {
      if (this.server.status === 'on') {
        const server = $('.server').eq(this.server.id - 1)
        let progress = server.find('.server__progress')
        progress.circleProgress({
          value: 1,
          thickness: 40,
          startAngle: 4.73,
          size: 120,
          fill: {
            image: this.progress_img,
          },
          animation: {
            duration: 5000,
            easing: 'circleProgressEasing',
          },
        })
      }
    },
  },
}
</script>

<style scoped>
.life__title_new {
  color: #00ff0c;
  font-size: 1.1em;
  font-weight: 900;
  white-space: nowrap;
  text-transform: uppercase;
}

.life__title_soon {
  color: #ff0000;
  font-size: 1.1em;
  font-weight: 900;
  white-space: nowrap;
  text-transform: uppercase;
}
</style>
